<template>
    <div>
        <div class="contact-page-head">
            <h2>Login</h2>
            <p>If your account is inactive, contact your local administration</p>
        </div>
        <div class="row">
        <form @submit.prevent="login">
            <div class="col-md-11" style="padding:0;">
                <!--Email Address-->
                <div class="col-sm-6 col-md-12 black"> <span class="input input--nariko"
                        :class="{ 'input--filled': userData.email }">
                        <input class="input__field input__field--nariko" type="email" id="input-4"
                            v-model="userData.email" style="background-color:transparent;" required />
                        <label class="input__label input__label--nariko" for="input-4"> <span
                                class="input__label-content input__label-content--nariko">Email
                                Address</span>
                        </label>
                    </span> </div>
                <div class="clearfix"></div>

                <div class="col-sm-6 col-md-12 black"> <span class="input input--nariko"
                        :class="{ 'input--filled': userData.password }">
                        <input class="input__field input__field--nariko" type="password" id="input-6"
                            v-model="userData.password" style="background-color:transparent;" required />
                        <label class="input__label input__label--nariko" for="input-6"> <span
                                class="input__label-content input__label-content--nariko">Password</span>
                        </label>
                    </span> </div>
                <div class="col-md-12 text-right">
                    <button class="ateeka_submit" type="submit">Login</button>
                </div>
                <div class="clearfix"></div>
            </div>
        </form>
        <div class="col-md-1"></div>
    </div>
    </div>
   
</template>

<script>
import axios from 'axios';
export default {
    name: "User_login",
    data() {
        return {
            userData: {
                email: '',
                password: ''
            }
        };
    },
    methods: {
        async login() {
            const baseUrl = process.env.VUE_APP_API_BASE_URL;
            let link = `${baseUrl}users/api/login/`
            console.log(link);
            console.log('login to this field')
            try {
                await axios.post(link, this.userData, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then(response => {
                    console.log(response)
                    if (response.status === 200) {
                        console.log(response.data);
                        this.$store.commit('login', {token:response.data.token,firstName:response.data.firstName});
                        this.raiseAlert('success', response.data.message);
                        this.navigateTo('client_shop_page');
                    } else {
                        console.log('Unexpected status:', response.status);
                        this.raiseAlert('', 'Invalid login request');
                    }
                })
            } catch (error) {
                console.log(error)
                if (error.response) {
                    console.log(error.response.data);
                    this.raiseAlert('', error.response.data.error);
                } else if (error.request) {// The request was made but no response was received
                    console.log(error.request);
                    this.raiseAlert(false, 'Invalid login request');
                } else {
                    console.log(error);
                    this.raiseAlert(false, 'Error in request setup');
                }

            }
        }
    }
}
</script>
